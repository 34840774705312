import React, { useState, useEffect } from 'react'
import { createStyles, withStyles, Paper } from '@material-ui/core'
import * as api from '../../../infrastructure/api'
import { muiOptions, defaultColors } from '../../../infrastructure/materialUiThemeProvider'
import { t } from '../../../infrastructure/i18nextHelper'
import { TextField, Select, DatePicker, Switch } from '../../common/customComponents'
import { DealContainer } from './dealEditStore'
import {
    Deal, SapVendorParams, SapVendor, PlantParams, Plant, ProductSapCode, ProductSapCodeParams,
    DealMovementType, ShipToParams, SapShipTo,
    SapCounterpartyDealTypeParams, SapCounterpartyDealType
} from '../dealModels'
import { SapButton } from '../../../infrastructure/sap/components/sapButton'
import { hasFeature } from '../../../infrastructure/feature'
import { hasClaim } from '../../../infrastructure/signIn/userContext'
import { Claims } from '../../../infrastructure/signIn/models'
import { FieldStatusStore } from '../../common/fieldsStatus'

type StringDate = string
type DealSapType = 'pcCreate' | 'scCreate'

function useSapFormState() {
    let store = DealContainer.useContainer()
    let deal = store.deal as Deal

    let [exchangeNumber, setExchangeNumber] = useState<string | null>(deal?.exchangeNumber)
    let [sapCounterpartyDealTypes, setSapCounterPartyDealTypes] = useState<SapCounterpartyDealType[]>([])
    let [sapVendorCode, setSapVendorCode] = useState<string | null>(deal?.sapVendorCode)
    let [sapVendors, setSapVendors] = useState<SapVendor[]>([])
    let [shipTo, setShipTo] = useState<string | null>(deal?.shipTo)
    let [soldTo, setSoldTo] = useState<string | null>(deal?.soldTo)
    let [sapShipTos, setSapShipTos] = useState<SapShipTo[]>([])
    let [plantCode, setPlantCode] = useState<string | null>(deal?.destinationPlant)
    let [productSapCode, setProductSapCode] = useState<string | null>(null)
    let [productSapCodes, setProductSapCodes] = useState<ProductSapCode[]>([])
    let [purchaseOrganization, setPurchaseOrganization] = useState<string | null>(null)
    let [purchaseGroup, setPurchaseGroup] = useState<string | null>(null)
    let [salesOrganization, setSalesOrganization] = useState<string | null>(null)
    let [salesOffice, setSalesOffice] = useState<string | null>(null)
    let [salesGroup, setSalesGroup] = useState<string | null>(null)
    let [distributionChannel, setDistributionChannel] = useState<string | null>(null)
    let [plants, setPlants] = useState<Plant[]>([])
    let [storageLocation, setStorageLocation] = useState<string | null>(deal?.destinationStorageLocation)
    let [storageLocations, setStorageLocations] = useState<string[]>([])
    let [contractReference, setContractReference] = useState<string | null>(deal?.contractReference)
    let [incoTerms, setIncoTerms] = useState<string | null>(deal?.incoTerms)
    let [incoTermsDescription, setIncoTermsDescription] = useState<string | null>(deal?.incoTermsDescription)
    let [agreementDate, setAgreementDate] = useState<StringDate | null>(deal?.agreementDate)
    let [useFinalPricingInSap, setUseFinalPricingInSap] = useState<boolean>(deal?.useFinalPricingInSap)

    useEffect(updateStore, [
        exchangeNumber,
        sapVendorCode,
        plantCode,
        storageLocation,
        productSapCode,
        purchaseOrganization,
        purchaseGroup,
        salesOrganization,
        salesOffice,
        salesGroup,
        distributionChannel,
        contractReference,
        incoTerms,
        incoTermsDescription,
        agreementDate,
        shipTo,
        soldTo,
        useFinalPricingInSap
    ])
    useEffect(updateSapCounterpartyDealType, [sapCounterpartyDealTypes])
    useEffect(updateSapVendor, [sapVendors])
    useEffect(updateSapShipTo, [sapShipTos])
    useEffect(updatePlant, [plants])
    useEffect(updateStorageLocation, [plantCode, plants])
    useEffect(updateProductSapCode, [productSapCodes])
    useEffect(updateFromDeal, [deal?.id])
    useEffect(() => { loadSapCounterpartyDealTypes() }, [deal?.id, deal?.company, deal?.counterpartyId, deal?.dealType])
    useEffect(() => { loadProductSapCodes() }, [deal?.id, deal?.productId, deal?.company])
    useEffect(() => { loadSapVendors() }, [deal?.id, deal?.counterpartyId, deal?.company])
    useEffect(() => { loadSapShipTos() }, [deal?.id, deal?.counterpartyId, deal?.company])
    useEffect(() => { loadPlants() }, [deal?.id, deal?.site, deal?.productId, deal?.dutyStatus, deal?.company])
    useEffect(() => {
        if (store.deal && store.deal.contractReference != contractReference)
            setContractReference(store.deal.contractReference)
    }, [store?.deal?.contractReference])

    function updateStore() {
        store.setDeal({
            ...deal,
            exchangeNumber,
            sapVendorCode,
            destinationPlant: plantCode,
            destinationStorageLocation: storageLocation,
            productSapCode,
            purchaseOrganization,
            purchaseGroup,
            salesOrganization,
            salesOffice,
            salesGroup,
            distributionChannel,
            contractReference,
            incoTerms,
            incoTermsDescription,
            agreementDate,
            shipTo,
            soldTo,
            useFinalPricingInSap
        })
    }

    function updateFromDeal() {
        setExchangeNumber(deal.exchangeNumber)
        setSapVendorCode(deal.sapVendorCode)
        setShipTo(deal.shipTo)
        setSoldTo(deal.soldTo)
        setPlantCode(deal.destinationPlant)
        setStorageLocation(deal.destinationStorageLocation)
        setProductSapCode(deal.productSapCode)
        setPurchaseOrganization(deal.purchaseOrganization)
        setPurchaseGroup(deal.purchaseGroup)
        setSalesOrganization(deal.salesOrganization)
        setSalesOffice(deal.salesOffice)
        setSalesGroup(deal.salesGroup)
        setDistributionChannel(deal.distributionChannel)
        setUseFinalPricingInSap(deal.useFinalPricingInSap)
    }

    function updateSapCounterpartyDealType() {
        if (sapCounterpartyDealTypes.length === 0) {
            setExchangeNumber(null)
            setPurchaseOrganization(null)
            setPurchaseGroup(null)
            setSalesOrganization(null)
            setSalesOffice(null)
            setSalesGroup(null)
            setDistributionChannel(null)
        }
        else {
            if (!exchangeNumber || !sapCounterpartyDealTypes.find(x => x.exchangeNumber === exchangeNumber))
                setExchangeNumber(sapCounterpartyDealTypes[0].exchangeNumber)

            if (!purchaseOrganization || !sapCounterpartyDealTypes.find(x => x.purchaseOrganization === purchaseOrganization))
                setPurchaseOrganization(sapCounterpartyDealTypes[0].purchaseOrganization)

            if (!purchaseGroup || !sapCounterpartyDealTypes.find(x => x.purchaseGroup === purchaseGroup))
                setPurchaseGroup(sapCounterpartyDealTypes[0].purchaseGroup)

            if (!salesOrganization || !sapCounterpartyDealTypes.find(x => x.salesOrganization === salesOrganization))
                setSalesOrganization(sapCounterpartyDealTypes[0].salesOrganization)

            if (!salesOffice || !sapCounterpartyDealTypes.find(x => x.salesOffice === salesOffice))
                setSalesOffice(sapCounterpartyDealTypes[0].salesOffice)

            if (!salesGroup || !sapCounterpartyDealTypes.find(x => x.salesGroup === salesGroup))
                setSalesGroup(sapCounterpartyDealTypes[0].salesGroup)

            if (!distributionChannel || !sapCounterpartyDealTypes.find(x => x.distributionChannel === distributionChannel))
                setDistributionChannel(sapCounterpartyDealTypes[0].distributionChannel)
        }
    }

    function updateSapVendor() {
        if (sapVendors.length === 0)
            setSapVendorCode(null)
        else if (!sapVendorCode || !sapVendors.find(x => x.code === sapVendorCode))
            setSapVendorCode(sapVendors[0].code)
    }

    function updateSapShipTo() {
        if (sapShipTos.length === 0) {
            setShipTo(null)
            setSoldTo(null)
        }
        else if (!shipTo || !sapShipTos.find(x => x.code === shipTo)) {
            setShipTo(sapShipTos[0].code)
            setSoldTo(sapShipTos[0].soldTo)
        }
    }

    function updatePlant() {
        let defaultPlantCode = plants.first(x => x.isDefault)?.code
        if (plants.length === 0)
            setPlantCode(null)
        else if (!plantCode || !plants.find(x => x.code === plantCode))
            setPlantCode(defaultPlantCode ?? plants[0].code)
    }

    function updateStorageLocation() {
        let plant = plants.find(x => x.code === plantCode)
        if (!plantCode || !plant || plant.storageLocations.length === 0) {
            setStorageLocation(null)
            setStorageLocations([])
        }
        else
            setStorageLocations(plant.storageLocations)
    }

    function updateProductSapCode() {
        if (productSapCodes.length === 0)
            setProductSapCode(null)
        else if (!productSapCode || !productSapCodes.find(x => x.sapCode === productSapCode))
            setProductSapCode(productSapCodes[0].sapCode)
    }

    async function loadSapCounterpartyDealTypes() {
        if (!deal.company || !deal.counterpartyId || !deal.dealType)
            return

        let parameters: SapCounterpartyDealTypeParams = {
            company: deal.company,
            counterpartyId: deal.counterpartyId,
            dealType: deal.dealType
        }

        let results = await api.get<SapCounterpartyDealType[]>('deal/sapCounterpartyDealType', parameters)
        setSapCounterPartyDealTypes(results)
    }

    async function loadSapVendors() {
        if (!deal.counterpartyId || !deal.company)
            return

        let parameters: SapVendorParams = {
            companyCode: deal.company,
            counterpartyId: deal.counterpartyId
        }

        let sapVendorsResults = await api.get<SapVendor[]>('deal/sapVendor', parameters)
        setSapVendors(sapVendorsResults)
    }

    async function loadSapShipTos() {
        if (!deal.counterpartyId || !deal.company)
            return

        let parameters: ShipToParams = {
            company: deal.company,
            counterpartyId: deal.counterpartyId
        }

        let sapShipTosResults = await api.get<SapShipTo[]>('deal/sapShipTo', parameters)
        setSapShipTos(sapShipTosResults)
    }

    async function loadPlants() {
        if (!deal.site || !deal.productId || !deal.dutyStatus || !deal.company)
            return

        let parameters: PlantParams = {
            site: deal.site,
            productId: deal.productId,
            dutyStatus: deal.dutyStatus,
            companyCode: deal.company
        }
        let plantResults = await api.get<Plant[]>('deal/plant', parameters)
        setPlants(plantResults)
    }

    async function loadProductSapCodes() {
        let productCode = store.products?.find(p => p.id === deal.productId)?.code
        let parameters: ProductSapCodeParams = {
            companyCode: deal.company,
            productCode: productCode!
        }
        let productSapCodes = await api.get<ProductSapCode[]>('deal/productSapCodeByCompanyAndProduct', parameters)
        setProductSapCodes(productSapCodes)
    }

    async function requestSalesContractCreation() {
        await store.saveDeal()
        await api.post('sapApi/salesContract', { dealId: deal.id })
        await store.loadDeal(deal.id)
    }

    async function requestPurchaseContractCreation() {
        await store.saveDeal()
        await api.post('sapApi/purchaseContract', { dealId: deal.id })
        await store.loadDeal(deal.id)
    }

    async function requestUpdateSapQuantity() {
        await store.saveDeal()
        await api.post('sapApi/updateDealSapQuantity', { dealId: deal.id })
        await store.loadDeal(deal.id)
    }

    function triggerRerender() {
        store.setDeal({ ...deal })
    }

    function setDas(das: string) {
        store.setDeal({ ...deal, das })
    }

    return {
        deal, exchangeNumber, setExchangeNumber, sapVendorCode, setSapVendorCode, shipTo, setShipTo, soldTo, setSoldTo, sapShipTos,
        plantCode, setPlantCode, storageLocation, setStorageLocation, plants, storageLocations, sapCounterpartyDealTypes, sapVendors,
        productSapCodes, productSapCode, setProductSapCode, purchaseOrganization, setPurchaseOrganization,
        purchaseGroup, setPurchaseGroup, salesOrganization, setSalesOrganization, salesOffice, setSalesOffice,
        salesGroup, setSalesGroup, distributionChannel, setDistributionChannel,
        fieldStatus: store.fieldStatus, costStatus: store.costStatus, triggerRerender, fromVessel: !!store.associatedVesselId,
        requestPurchaseContractCreation, requestSalesContractCreation, contractReference, setContractReference, incoTerms, setIncoTerms, incoTermsDescription,
        setIncoTermsDescription, agreementDate, setAgreementDate, setDas, requestUpdateSapQuantity, isInternal: store.isInternal,
        useFinalPricingInSap, setUseFinalPricingInSap
    }
}

const dealRequirements: { [P in DealSapType]: { [P in keyof Deal]?: 'filled' | 'empty' } } = {
    'pcCreate': {
        referenceNumber: 'filled',
        movementType: 'filled',
        dealType: 'filled',
        counterpartyId: 'filled',
        dutyStatus: 'filled',
        company: 'filled',
        purchaseGroup: 'filled',
        purchaseOrganization: 'filled',
        sapVendorCode: 'filled',
        productId: 'filled',
        site: 'filled',
        destinationPlant: 'filled',
        destinationStorageLocation: 'filled',
        productSapCode: 'filled',
        volume: 'filled',
        validFrom: 'filled',
        validTo: 'filled',
        currency: 'filled',
        contractReference: 'empty'
    },
    'scCreate': {
        referenceNumber: 'filled',
        movementType: 'filled',
        dealType: 'filled',
        counterpartyId: 'filled',
        company: 'filled',
        sapVendorCode: 'filled',
        productId: 'filled',
        validFrom: 'filled',
        validTo: 'filled',
        volume: 'filled',
        site: 'filled',
        productSapCode: 'filled',
        exchangeNumber: 'filled',
        shipTo: 'filled',
        currency: 'filled',
        quantityPriceUnit: 'filled',
        contractReference: 'empty'
    }
}

type SapFormProps = { className?: string, hide?: boolean, classes?: any }

function SapForm({ className, hide, classes }: SapFormProps) {
    if (hide) return (<></>)

    let state = useSapFormState()

    let tBase = 'deals.label.sap.'

    function isButtonDisabled(type: DealSapType): boolean {
        let sapActionRequirements = dealRequirements[type]
        let requiredFieldsInError = Object.keys(sapActionRequirements).filter(prop =>
            sapActionRequirements[prop] === 'filled' && (state.deal[prop] == null || state.deal[prop] === '')
            || sapActionRequirements[prop] === 'empty' && (state.deal[prop] != null && state.deal[prop] != ''))
        return requiredFieldsInError.length > 0
    }

    function onHelpClick(type: DealSapType) {
        state.fieldStatus.clearStatuses()
        state.costStatus.clearStatuses()

        let costsReqs: PropRequirement[] =
            state.deal.fields.filter(x => x.sapConditionType).map(x => ({ prop: x.code, requirement: 'filled' }))

        let sapActionRequirements = dealRequirements[type]
        let dealReqs: PropRequirement[] =
            Object.keys(sapActionRequirements).map(x => ({ prop: x, requirement: sapActionRequirements[x] }))

        highlightProps(costsReqs, state.costStatus)
        highlightProps(dealReqs, state.fieldStatus)

        state.triggerRerender()

        function highlightProps<T>(requirements: PropRequirement[], fieldStatus: FieldStatusStore<T>) {
            let info = requirements.filter(x => x.requirement === 'filled').map(x => x.prop)
            fieldStatus.setStatus(info, 'info')

            let warning = requirements.filter(x => x.requirement === 'empty').map(x => x.prop)
            fieldStatus.setStatus(warning, 'warning')
        }
        type PropRequirement = { prop: string, requirement: 'empty' | 'filled' }
    }

    return (
        <Paper className={className + ' ' + classes.paper} elevation={0}>
            <div>
                <TextField label={t('deals.label.sap.contractReference')}
                    text={state.contractReference ?? ''}
                    disabled={!hasClaim(Claims.DealManager)}
                    status={state.fieldStatus.getStatus('contractReference')}
                    onChange={e => state.setContractReference(e.target.value)}
                    overrideStyle={{ root: classes.contractReferenceText }} />
                {(state.deal.movementType == DealMovementType.PURCHASE || state.deal.movementType == DealMovementType.SALE)
                    && hasFeature('ProvisionalPricing')
                    && <Switch isChecked={state.useFinalPricingInSap}
                        offText={t('deals.label.sap.provisionalPrice')}
                        onText={t('deals.label.sap.finalPrice')}
                        changeCallback={() => { state.setUseFinalPricingInSap(!state.useFinalPricingInSap) }} />}
                {state.deal.movementType == DealMovementType.PURCHASE
                    && <SapButton
                        className={classes.sapButton}
                        disabled={isButtonDisabled('pcCreate')}
                        hide={!hasClaim(Claims.DealManager) || !hasFeature('SapPurchaseContractCreate')}
                        onHelpClick={() => onHelpClick('pcCreate')}
                        label={t(`deals.label.sap.createPurchaseContractButton`)}
                        tooltip={t(`deals.label.sap.createPurchaseContractTooltip`)}
                        onClick={state.requestPurchaseContractCreation} />}
                {state.deal.movementType == DealMovementType.SALE
                    && <SapButton
                        className={classes.sapButton}
                        disabled={isButtonDisabled('scCreate')}
                        hide={!hasClaim(Claims.DealManager) || !hasFeature('SapSalesContractCreate')}
                        onHelpClick={() => onHelpClick('scCreate')}
                        label={t(`deals.label.sap.createSalesContractButton`)}
                        tooltip={t(`deals.label.sap.createSalesContractTooltip`)}
                        onClick={state.requestSalesContractCreation} />}
                {hasFeature('DealsSapQuantityVolumeUpdate') &&
                    <SapButton
                        className={classes.sapButton}
                        disabled={!state.contractReference}
                        label={t(`deals.label.sap.updateDealSapQuantityButton`)}
                        tooltip={t(`deals.label.sap.updateDealSapQuantityTooltip`)}
                        onClick={state.requestUpdateSapQuantity} />}
            </div>
            <div>
                <div>
                    <TextField label={t('deals.label.sap.incoTerms')}
                        text={state.incoTerms}
                        status={state.fieldStatus.getStatus('incoTerms')}
                        maxLength={3}
                        onChange={event => state.setIncoTerms(event.target.value)} />
                    <TextField label={t('deals.label.sap.incoTermsDescription')}
                        text={state.incoTermsDescription}
                        status={state.fieldStatus.getStatus('incoTermsDescription')}
                        onChange={event => state.setIncoTermsDescription(event.target.value)} />
                    <DatePicker date={state.agreementDate}
                        status={state.fieldStatus.getStatus('agreementDate')}
                        label={t('deals.label.sap.agreementDate')}
                        setDate={newDate => { if (newDate) state.setAgreementDate(newDate) }} />
                </div>
            </div>
            <div>
                <div>
                    {hasFeature('SAPExchangeNumber')
                        && <Select
                            label={t('deals.label.sap.exchangeNumber')}
                            disabled={state.isInternal()}
                            status={state.fieldStatus.getStatus('exchangeNumber')}
                            value={state.exchangeNumber}
                            choices={state.sapCounterpartyDealTypes.map(x => x.exchangeNumber).distinct().filter(x => x != null).map(x => x!)}
                            onChange={val => state.setExchangeNumber(val)} />
                    }
                    {state.deal.movementType == DealMovementType.SALE
                        && <Select
                            label={t('deals.label.sap.shipTo')}
                            disabled={state.deal.generateMktSaleForecasts}
                            status={state.fieldStatus.getStatus('shipTo')}
                            value={state.shipTo}
                            choices={state.sapShipTos.map(x => ({ value: x.code, text: `${x.code} - ${x.name}` }))}
                            onChange={val => state.setShipTo(val)} />
                    }
                    {state.deal.movementType == DealMovementType.SALE
                        && <Select
                            label={t('deals.label.sap.soldTo')}
                            disabled={true}
                            status={state.fieldStatus.getStatus('soldTo')}
                            value={state.soldTo}
                            choices={state.sapShipTos.map(x => ({ value: x.soldTo, text: `${x.soldTo} - ${x.soldToName}` }))}
                            onChange={val => state.setSoldTo(val)} />
                    }
                    {state.deal.movementType == DealMovementType.PURCHASE
                        && <Select
                            label={t('deals.label.sap.vendor')}
                            disabled={state.deal.generateMktSaleForecasts}
                            status={state.fieldStatus.getStatus('sapVendorCode')}
                            value={state.sapVendorCode}
                            choices={state.sapVendors.map(x => x.code)}
                            onChange={val => state.setSapVendorCode(val)} />
                    }
                    <Select
                        label={t(`deals.label.sap.destinationPlant`)}
                        status={state.fieldStatus.getStatus('destinationPlant')}
                        disabled={state.plants.length <= 1}
                        value={state.plantCode ?? ''}
                        choices={state.plants.map(x => x.code)}
                        onChange={val => state.setPlantCode(val)} />
                    <Select
                        label={t(`deals.label.sap.destinationStorageLocation`)}
                        status={state.fieldStatus.getStatus('destinationStorageLocation')}
                        disabled={state.storageLocations.length <= 1}
                        value={state.storageLocation ?? ''}
                        choices={state.storageLocations}
                        onChange={val => state.setStorageLocation(val)} />
                    <Select
                        label={t(`deals.label.sap.productSapCode`)}
                        status={state.fieldStatus.getStatus('productSapCode')}
                        disabled={state.productSapCodes.length <= 1}
                        value={state.productSapCode ?? ''}
                        choices={state.productSapCodes.map(x => x.sapCode)}
                        onChange={val => state.setProductSapCode(val)} />
                    {state.deal.movementType == DealMovementType.PURCHASE
                        && <Select
                            label={t(tBase + 'purchaseOrganization')}
                            status={state.fieldStatus.getStatus('purchaseOrganization')}
                            value={state.purchaseOrganization ?? ''}
                            choices={state.sapCounterpartyDealTypes.map(x => x.purchaseOrganization).distinct().filter(x => x != null).map(x => x!)}
                            onChange={val => state.setPurchaseOrganization(val)} />
                    }
                    {state.deal.movementType == DealMovementType.PURCHASE
                        && <Select
                            label={t(tBase + 'purchaseGroup')}
                            status={state.fieldStatus.getStatus('purchaseGroup')}
                            value={state.purchaseGroup ?? ''}
                            choices={state.sapCounterpartyDealTypes.map(x => x.purchaseGroup).distinct().filter(x => x != null).map(x => x!)}
                            onChange={val => state.setPurchaseGroup(val)} />
                    }
                    {state.deal.movementType == DealMovementType.SALE
                        && <Select
                            label={t(tBase + 'salesOrganization')}
                            status={state.fieldStatus.getStatus('salesOrganization')}
                            value={state.salesOrganization ?? ''}
                            choices={state.sapCounterpartyDealTypes.map(x => x.salesOrganization).distinct().filter(x => x != null).map(x => x!)}
                            onChange={val => state.setSalesOrganization(val)} />
                    }
                    {state.deal.movementType == DealMovementType.SALE
                        && <Select
                            label={t(tBase + 'distributionChannel')}
                            status={state.fieldStatus.getStatus('distributionChannel')}
                            value={state.distributionChannel ?? ''}
                            choices={state.sapCounterpartyDealTypes.map(x => x.distributionChannel).distinct().filter(x => x != null).map(x => x!)}
                            onChange={val => state.setDistributionChannel(val)} />
                    }
                    {state.deal.movementType == DealMovementType.SALE
                        && <Select
                            label={t(tBase + 'salesOffice')}
                            status={state.fieldStatus.getStatus('salesOffice')}
                            value={state.salesOffice ?? ''}
                            choices={state.sapCounterpartyDealTypes.map(x => x.salesOffice).distinct().filter(x => x != null).map(x => x!)}
                            onChange={val => state.setSalesOffice(val)} />
                    }
                    {state.deal.movementType == DealMovementType.SALE
                        && <Select
                            label={t(tBase + 'salesGroup')}
                            status={state.fieldStatus.getStatus('salesGroup')}
                            value={state.salesGroup ?? ''}
                            choices={state.sapCounterpartyDealTypes.map(x => x.salesGroup).distinct().filter(x => x != null).map(x => x!)}
                            onChange={val => state.setSalesGroup(val)} />
                    }
                </div>
            </div>
            {hasFeature('SAPTax')
                && <div>
                    <div>
                        <Select
                            label={t(`deals.label.sap.das`)}
                            value={state.deal.das}
                            choices={['TAX', 'UNTAX', 'UN-01']}
                            onChange={val => state.setDas(val)} />
                        <TextField
                            label={t(`deals.label.sap.handlingType`)}
                            text={state.deal.sapHandlingType}
                            disabled={true} />
                        <TextField
                            label={t(`deals.label.sap.valuationType`)}
                            text={state.deal.sapValuationType}
                            disabled={true} />
                    </div>
                </div>}
        </Paper>)
}

let styles = _ =>
    createStyles({
        paper: {
            display: 'flex',
            flexDirection: 'column',
            '& > *': {
                marginBottom: '3em',
                display: 'flex',
                justifyContent: 'space-between',
                '& :last-child': {
                    marginBottom: 0
                }
            }
        },
        paperTitle: {
            color: defaultColors.red.main.color,
            margin: '1em'
        },
        hide: {
            display: 'none'
        },
        sapButton: {
            marginRight: '1.5em'
        },
        contractReferenceText: {
            marginRight: 'auto'
        }
    })

export let DealSapForm = withStyles(styles, muiOptions)(SapForm)