import { Guid } from '../../infrastructure/guid'
import { IndexType } from '../pricings/pricingModels'
import { MovementStatus, MovementType, SapFlowStepStatus } from '../stock/stockModels'

type StringDate = string

export type Deal = {
    id: Guid
    referenceNumber: string | null
    productId: Guid | null
    dealType: string
    dealTypeOverwritten: boolean
    movementType: string
    dutyStatus: string | null
    paymentTerms: string | null
    paymentTermsOverwritten: boolean
    company: string
    country: string
    site: string
    validFrom: StringDate
    validTo: StringDate
    paymentTermsDate: StringDate | null
    volume: number | null
    volumeOverwritten: boolean
    quantity: number | null
    quantityOverwritten: boolean
    conversionFactor: number
    counterpartyId: Guid | null
    mirrorDealId: Guid | null
    comment: string | null
    transporterId: Guid | null

    pricingType: DealPricingType
    contractReference: string | null
    sapVendorCode: string | null
    shipTo: string | null
    soldTo: string | null
    destinationPlant: string | null
    destinationStorageLocation: string | null

    generateMktSaleForecasts: boolean

    fields: FieldState[]
    borderCountryCurrency: string | null
    quotationPeriod: QuotationPeriod
    fixedPrice: FixedPrice

    movements: DealMovementItem[]

    assignee: DealAssignee
    assigneeOverwritten: boolean
    provisionalPricesValidation: ValidationProcessForm | null
    finalPricesValidation: ValidationProcessForm | null
    currency: string | null
    productSapCode: string | null
    purchaseOrganization: string | null
    purchaseGroup: string | null
    salesOrganization: string | null
    salesOffice: string | null
    salesGroup: string | null
    distributionChannel: string | null
    underTolerance: number | null
    overTolerance: number | null
    toleranceOption: string | null
    incoTerms: string | null
    incoTermsDescription: string | null
    agreementDate: StringDate | null

    kind: DealKind
    status: string
    signed: boolean
    isManual: boolean
    quantityUnit: string | null
    quantityPriceUnit: string | null
    meanOfTransportation: string | null

    finalPricing: MultiplePriceIndexPricingPeriod
    provisionalPricing: MultiplePriceIndexPricingPeriod
    dealNumber: number
    exchangeNumber: string | null
    termSpot: string
    sapQuantity: number | null
    sapQuantityOverwritten: boolean
    frontOfficer: string | null
    das: string | null
    sapHandlingType: string | null
    sapValuationType: string | null
    movementQuantity: number | null
    useFinalPricingInSap: boolean
}

export type MultiplePriceIndexPricingPeriod = {
    pricingPeriods: DealPricingPeriod[]
    priceIndexes: DealPriceIndex[]
}

export type MultiplePricingBlockType = 'Final' | 'Provisional'

export type DealFieldGroup = 'final' | 'provisional' | 'custom' | 'default'

export type DealPriceIndex = {
    id: Guid
    priceIndexCode: string
    percentage: number
}

export type DealPricingPeriod = {
    id: Guid
    start: StringDate | null
    end: StringDate | null
    premium: number
    premiumUnit: string
    percentage: number
    baseUnitPrice: number | null
}

export type Plant = {
    code: string
    storageLocations: string[],
    isDefault: boolean
}

export type PlantParams = {
    site: string
    productId: string
    dutyStatus: string
    companyCode: string
}

export type SapCounterpartyDealType = {
    exchangeNumber: string | null
    purchaseOrganization: string | null
    purchaseGroup: string | null
    salesOrganization: string | null
    salesOffice: string | null
    salesGroup: string | null
    distributionChannel: string | null
}

export type SapVendor = {
    code: string
}

export type SapShipTo = {
    code: string
    name: string
    soldTo: string | null
    soldToName: string | null
    exists: boolean
}

export type SapCounterpartyDealTypeParams = {
    company: string
    counterpartyId: Guid
    dealType: string
}

export type SapVendorParams = {
    companyCode: string
    counterpartyId: string
}

export type ShipToParams = {
    company: string
    counterpartyId: string
}

export type ProductSapCodeParams = {
    productCode: string
    companyCode: string
}

export type ProductSapCode = {
    productCode: string
    sapCode: string
    companyCode: string
}

export type ValidationProcessForm = {
    type: string
    version: string
    status: string
    steps: string[]
    validationSteps: ValidationStep[]
}

export type ValidationStep = {
    name: string
    validatorLogin: string
    comment: string | null
    allValidators: UserForm[]
}

export type UserForm = {
    login: string
    name: string
}

export type DealErrors = {
    onCompany: boolean
    onDutyStatus: boolean
}

export type DealAssignee = {
    userName: string
    fullName: string
}

export type FieldState = {
    code: string
    price: number | null
    currencyPerQuantityUnit: string
    includedInTotal?: boolean
    group: DealFieldGroup
    groupOrder: number
    readOnly: boolean
    sapConditionType: string
    sapWhere: string
    precision: number | null
}

export type QuotationPeriod = {
    quotation: { value: String | null, unit: String | null } & Overwrittable
    premium: { value: number | null, unit: String } & Overwrittable
    start: { value: StringDate | null } & Overwrittable
    end: { value: StringDate | null } & Overwrittable
}
type Overwrittable = { isOverwritten: boolean, readOnly: boolean }

export type FixedPrice = {
    premium: { value: number | null, unit: String } & Overwrittable
    price: { value: number | null, unit: String } & Overwrittable
    date: { value: StringDate | null } & Overwrittable
}

export type DealListItem = {
    id: Guid
    type: string | null
    movementType: string | null
    referenceNumber: string | null
    dutyStatus: string | null
    company: string | null
    counterparty: Counterparty | null
    validFrom: Date | null
    validTo: Date | null
    productCode: string | null
    volume: number | null
    site: string | null
    nextValidator: string | null
    contractReference: string | null
    meanOfTransportation: string | null
    signed: boolean
    generateMktSaleForecasts: boolean
    assignee: string | null
    assigneeFullName: string | null
    currency: string | null
    das: string | null
    dealConsumption: number | null
    dealNumber: number
    exchangeNumber: string | null
    frontOfficer: string | null
    frontOfficerFullName: string | null
    movementQuantity: number | null
    overTolerance: number | null
    overToleranceQuantity: number | null
    pricingType: string
    quantity: number | null
    quantityPriceUnit: string | null
    sapHandlingType: string | null
    sapPlant: string | null
    sapQuantity: number | null
    sapValuationType: string | null
    sapVendor: string | null
    shipTo: string | null
    status: string
    termSpot: string
    toleranceOption: string | null
    underTolerance: number | null
    underToleranceQuantity: number | null
    unit: string | null
    isQuantityInTolerance: boolean
}

export type DealMovementItem = {
    movementId: Guid
    volume: number | null
    movementType: MovementType | null
    reference: string | null
    date: StringDate | null
    dutyStatus: string | null
    actualVolume: number | null
    text: string | null
    subText: string | null
    movementStatus: MovementStatus | null
    mainSapFlowListItem: { steps: SapFlowStepStatus[] }
    secondSapFlowListItem?: { steps: SapFlowStepStatus[] }
    meanOfTransportation: string | null
    associatedDealId: Guid
    associatedVesselId: Guid
    associatedTruckId: Guid
}

export enum DealTypes {
    OTS = "OTS",
    LOCAL = "LOCAL",
    EXPORT = "EXPORT",
    BPS = "BPS",
    IMPORT = "IMPORT",
    TRIGGER = "TRIGGER",
    EXCH = "EXCH",
    GAIN_LOSS = "GAIN/LOSS",
    PAPER = "PAPER",
    EXPOADJ = "EXPOADJ"
}

export type Counterparty = {
    id: Guid,
    name: string
}

export type SapCounterparty = {
    id: Guid
    counterpartyId: Guid
    vendorCode: string | null
    vendorName: string | null
    shipToCode: string | null
    shipToName: string | null
    companyCode: string
    customerSegment: string | null
}

export type Site = {
    code: string,
    name: string,
    siteGroup: string
    dutyStatuses: DutyStatus[]
}

export type Company = {
    code: string,
    name: string
    dutyStatuses: DutyStatus[]
    companyCurrency: string
}

export type DutyStatus = string

export enum DealMovementType {
    SALE = 'SALE',
    PURCHASE = 'PURCH',
    STATUSCHANGE = 'CHANG',
    TRANSFER = 'TRAN',
    REBRANDING = 'REBR'
}

export function GetMovementType(dealMovementType: string): MovementType {
    switch (dealMovementType) {
        case DealMovementType.SALE: return MovementType.Sale
        case DealMovementType.PURCHASE: return MovementType.Purchase
        case DealMovementType.STATUSCHANGE: return MovementType.StatusChange
        case DealMovementType.TRANSFER: return MovementType.Transfer
        case DealMovementType.REBRANDING: return MovementType.Rebranding
        default: return MovementType.Unknown
    }
}

export function GetIsIn(movementType: MovementType): boolean {
    let isIn = false
    switch (movementType) {
        case MovementType.Purchase:
        case MovementType.Borrow:
        case MovementType.Untriggered:
        case MovementType.Rebranding:
        case MovementType.Transfer:
        case MovementType.Gains:
        case MovementType.StatusChange:
            isIn = true
            break
        case MovementType.Sale:
        case MovementType.Loan:
        case MovementType.Transfer:
        case MovementType.Rebranding:
        case MovementType.Losses:
        case MovementType.StatusChange:
            isIn = false
            break
        default:
            isIn
            break
    }
    return isIn
}

export type SupplyProduct = {
    id: Guid
    code: string
    conversionFactor: number
    defaultQuotationCode: string
    unit: string
}

export type PriceIndex = {
    medecoCode: string
    name: string
    unitOfMeasurement: string
    longName: string
    currency: string
    type: IndexType
}

export type SearchFields = {
    sites: Site[]
    companies: Company[]
    counterparties: Counterparty[]
    products: SupplyProduct[]
    dealAssignees: DealAssignee[]
    dealTypes: string[]
    statuss: string[]
    moTs: string[]
}

export type DealFilter = {
    movementTypes: string[]
    query?: string | null
    companys?: string[] | null
    sites: string[] | null
    moTs?: string[] | null
    termSpot?: string[] | null
    dutyStatuss?: string[] | null
    validOn?: string | null
    validInStart?: string | null
    validInEnd?: string | null
    counterpartyIds?: Guid[] | null
    productIds?: Guid[] | null
    dealTypes?: string[] | null
    currentUserDealsOnly: boolean
    isDayPeriodMode: boolean
    statuss?: string[] | null
    signedNotSigned?: string[] | null
    dealAssignees?: string[] | null
    frontOfficers?: string[] | null
}

export enum DealKind {
    Creating,
    Autonomous,
    Linked,
    Duplicate
}

export type DuplicateDealCommand = {
    sourceId: Guid
    duplicateId: Guid
}

export type CreateMovementFromDeal = {
    dealId: Guid
    movementId: Guid
}

export type DealForecastMktSales = {
    dealId: Guid
}

export type CollapsableSection = 'pricingInformation' | 'sap' | 'movements' | 'DealProvisionalPrices' | 'DealFinalPrices'

export enum DealStatus {
    Mandate = 'Mandate',
    Negociated = 'Negociated',
    Cancelled = 'Cancelled',
    Closed = 'Closed',
}

export function MapDealFieldGroup(blockType: MultiplePricingBlockType): DealFieldGroup | undefined {
    switch (blockType) {
        case 'Final': return 'default'
        case 'Provisional': return 'provisional'
        default: return undefined
    }
}

export type DealFieldUnit = 'QuotationCurrency' | 'ProvisionalQuotationCurrency' | 'DealCurrency' | 'CompanyCurrency'
    | 'IndexPriceUnit' | 'QuantityPriceUnit' | 'CompanyCurrencyProductQuantityUnit' | 'ProvisionalIndexPriceUnit'

export type Period = {
    from: StringDate | null
    to: StringDate | null
}

export type DealPricingType = 'Formula' | 'Trigger' | 'Fixed'